import React from 'react';
import { PageProps } from 'gatsby';

import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';
import { Home } from '../components/pages/Home';

interface DataProps {}

const IndexPage: React.FC<PageProps<DataProps>> = () => (
  <Layout>
    <SEO title="Home" />
    <Home />
  </Layout>
);

export default IndexPage;
