import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export const Home: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "jason-leung-Xaanw0s0pMk-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareImage: file(relativePath: { eq: "brand-assets/1x/hmw_square.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      programImage: file(relativePath: { eq: "damiano-lingauri-uW_oj3jOPRg-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      makersImage: file(relativePath: { eq: "seyedeh-hamideh-kazemi-PFUqfNsorJM-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      newsImage: file(relativePath: { eq: "mockaroon-lgi3W65lmvA-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      venuesImage: file(relativePath: { eq: "thomas-renaud-tEzFyBNxcJg-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <section>
        <div className="container">
          23 - 29 november 2020 - Hoe maakt u het?
          <div className="py-16 md:flex">
            <div className="md:w-1/2">
              <Img fluid={data.squareImage.childImageSharp.fluid}></Img>
            </div>
            <div className="md:w-1/2 flex justify-center items-center">
              <div className="px-16 text-2xl font-header font-bold">
                {/* <h3 className="my-16 text-3xl uppercase font-heading font-bolder italic">Hoe maakt u het?</h3> */}
                <h3 className="my-8 text-2xl font-heading font-light">
                  Samen vieren we het makerschap door de hele stad.
                </h3>
                <h3 className="my-8 font-light">Over creatie, inspiratie en samenwerking in Den Haag.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="container">
          <div className="table">
            <h3 className="text-xl font-heading font-bold align-middle table-cell">MAAK</h3>
            <span className="text-pink text-6xl font-bold align-middle table-cell">&amp;</span>
          </div>
          <p>
            De Haagse Makers Week is eenn jaarlijkse week van activiteiten om het makerschap te vieren. Do it together:
            transdisciplinair maken in Den Haag door middel van talks, studio tours, workshops, en zelf-georganisserde
            tours.
          </p>
          <p>
            In 2020 delen we het programma op in MAAK&amp;. Hoe werken makers samen met andere disciplines om ideeën uit
            te wisselen en nieuwe dingen te maken. Doe mee met de conversatie.
          </p>
        </div>
      </section>
    </React.Fragment>
  );
};
